import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const channelcodeApi = createAsyncThunk(
  "auth/channelcodes",
  async (data, { rejectWithValue }) => {
    try {
      const paramsData = new URLSearchParams(data);
      const response = await AuthAxios.get(
        apiRoutes.channelCodeAction("list"), { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = { result: [], loading: false, error: null, message: "", total: 0 }

const channelcodeSlice = createSlice({
  name: "channelcode",
  initialState,
  reducers: {
    resetChannelCode: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(channelcodeApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channelcodeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(channelcodeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetChannelCode } = channelcodeSlice.actions;
export const channelcodeReducer = channelcodeSlice.reducer;
