import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getParticularcompetitionAPI = createAsyncThunk(
  "auth/competition/getPaticular",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.holidayHomeWorkAction(`${id}`, 1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getparticularDetailscompetitionSlice = createSlice({
  name: "get particular competition",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetgetparticularcompetition: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getParticularcompetitionAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticularcompetitionAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getParticularcompetitionAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetgetparticularcompetition } = getparticularDetailscompetitionSlice.actions;
export const getparticularDetailscompetitionReducer =
  getparticularDetailscompetitionSlice.reducer;
