import React, { useContext } from 'react'
import { AuthContext } from "../../context/AuthContext";
import AnalyticsListing from './AnalyticsListing';
import ChannelAnalyticsListing from './ChannelAnalyticsListing';
import { useDispatch, useSelector } from "react-redux";
export default function Index() {
    const auth = useContext(AuthContext);
    const { result: currentUserList, loading: currentUserLoading } = useSelector((state) => state?.currentUser);
    return (
        <>
            {auth?.userData?.is_subadmin === true && <ChannelAnalyticsListing />}
            {auth?.userData?.is_subadmin === false && <AnalyticsListing />}
        </>
    )
}
