import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const teachingCategoryEditApi = createAsyncThunk(
    'auth/teachingCategory/edit',
    async (data, { rejectWithValue }) => {
        try {
            const { id, teaching_category, ...rest } = data
            const response = await AuthAxios.patch(apiRoutes.resourceCategoryAction(`${id}`, 1), rest, { params: { teaching_category } })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const teachingCategoryEditSlice = createSlice({
    name: 'teaching category edit',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(teachingCategoryEditApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingCategoryEditApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(teachingCategoryEditApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const teachingCategoryEditReducer = teachingCategoryEditSlice.reducer