import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import apiRoutes from "../../store/apiConfigs";
import DataGridComponent from "../../components/DataGridComponent";
import { assignedObjectListApi, resetAssignedObjectList } from "../../store/channel/assignedObjectsListing";
import { assignObjectToChannelApi } from "../../store/channel/assignObjectToChannel";
import { unAssignObjectFromChannelApi } from "../../store/channel/unAssignObjectFromChannel";
import CustomGridToolbar from "../../components/CustomGridToolbarWithoutDownload";
import CheckIcon from "@mui/icons-material/Check";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { AuthContext } from "../../context/AuthContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ChannelAssignedModuleList({ getRowSpacing, NoRowsOverlay, channelCategory, channel_id, callModulelistList }) {
  const { result: assignedObjectListResult, loading: assignedObjectLoading, total: assignedObjectTotal } = useSelector((state) => state?.assignedObjectList);

  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const [queryOptionsChangeAssignedModule, setQueryOptionsChangeAssignedModule] = useState({});
  const [paginationModelChangeAssignedModule, setPaginationModelChangeAssignedModule] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationModelAssignedMemoized = useMemo(() => paginationModelChangeAssignedModule, [paginationModelChangeAssignedModule]);

  const onFilterChangeAssignedModule = useCallback((filterModel) => {
    setQueryOptionsChangeAssignedModule({ filterModel: { ...filterModel } });
  }, []);

  const createQueryParams = useCallback((paginationModel, queryOptions) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    return {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      channel_id,
      type: channelCategory
    };
  }, [channel_id, channelCategory]);

  const callObjectList = useCallback(() => {
    const queryAssignedModule = createQueryParams(paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule);
    dispatch(assignedObjectListApi(queryAssignedModule));
  }, [createQueryParams, dispatch, paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule])

  const handleRemoveFromChannel = async (record) => {
    dispatch(unAssignObjectFromChannelApi({
      type: record.module_type ? record.module_type === "Course" ? "Courses" : record.module_type : record.module_type,
      ID: record.id
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
        callModulelistList()
      }
    }).catch(err => console.log(err))
  }

  const handlePublishUnpublishChannel = async (record) => {
    dispatch(assignObjectToChannelApi({
      type: record.row.module_type ? record.row.module_type === "Course" ? "Courses" : record.row.module_type : record.row.module_type,
      module_id: record.row.module_id,
      channel_id,
      published: !record.row.published
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
      }
    }).catch(err => console.log(err))
  }

  const handlePaginationModelChangeAssigned = (model) => {
    if (
      paginationModelChangeAssignedModule.page !== model.page ||
      paginationModelChangeAssignedModule.pageSize !== model.pageSize
    ) {
      setPaginationModelChangeAssignedModule(model);
    }
  };

  // Fetch assigned module data
  useEffect(() => {
    callObjectList()
  }, [dispatch, paginationModelAssignedMemoized, queryOptionsChangeAssignedModule, callObjectList, channelCategory]);

  const assignedObjectListColumn = [
    {
      field: "title",
      headerName: "Title",
      width: 450,
      filterable: true,
      sortable: false,
      hideable: false,
      renderCell: (data) => (<>
        {data.row.object_data.title}
      </>)
    },
    {
      field: "module_type",
      headerName: "Content Type",
      width: 200,
      filterable: false,
      sortable: false,
      hideable: false
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 300,
      disableExport: true,
      renderCell: (data) => (
        <Box display={"flex"} justifyItems={"center"} alignItems={"center"} justifyContent={"space-around"} width={"100%"}>
          <Button color="success" variant="contained"
            endIcon={data?.row?.published ? <CheckIcon /> : <ArrowRightIcon />}
            onClick={() => handlePublishUnpublishChannel(data)}
          >
            {data?.row?.published ? "UnPublish" : "Publish"}
          </Button>
          {!auth?.userData?.is_subadmin &&(<Button
            color="info"
            endIcon={<RemoveIcon />}
            label="Available"
            variant="outlined"
            onClick={() => handleRemoveFromChannel(data.row)}
          >
            Remove
          </Button>)}
        </Box>
      ),
    },
  ];
  return (
    <>
      <DataGridComponent
        loading={assignedObjectLoading}
        rowCount={assignedObjectTotal}
        pageSize={paginationModelChangeAssignedModule?.pageSize}
        onPageSizeChange={(newPageSize) => {
          setPaginationModelChangeAssignedModule({
            ...paginationModelChangeAssignedModule,
            pageSize: newPageSize,
          })
        }}
        paginationModel={paginationModelAssignedMemoized}
        onPaginationModelChange={handlePaginationModelChangeAssigned}
        getRowId={(row) => `${row.id}${row.module_id}${row.module_type}${uuidv4()}`}
        rows={assignedObjectListResult}
        getRowSpacing={getRowSpacing}
        columns={assignedObjectListColumn}
        onFilterModelChange={onFilterChangeAssignedModule}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: (props) =>
          (CustomGridToolbar({
            ...props, moduleType: "assignedObject", queryOptions: queryOptionsChangeAssignedModule, paginationModel: paginationModelChangeAssignedModule, apiUrl: apiRoutes.channelAction('assign-object/list')
          })),
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'linear-progress',
          },
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </>
  );
}
