import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { default as React, useEffect, useState } from 'react'
import { AiOutlineBell, AiOutlineInbox, AiOutlineMessage, AiOutlineSetting } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import NotificationList from './notifications/NotificationList'
import { userCountApi } from '../../store/dashboard/userCount'
import { recentUserCountApi } from '../../store/dashboard/recentUserCount'

export default function ChannelDashboardData({ dashboardData }) {

    const dispatch = useDispatch()
    const userCount = useSelector((state) => state?.userCount?.result);
    const recentUserCount = useSelector((state) => state?.recentUserCount?.result);
    const [notificationListShow, setNotificationListShow] = useState(false)

    console.log()

    return (
        <Stack spacing={2}>
            <Card sx={{ py: 2 }}>
                <Typography fontWeight={600} variant='h5' my={1}>Notifications & Updates</Typography>
                <Grid container rowGap={2}>
                    {dashboardData && Object.keys(dashboardData).length > 0 && Object.keys(dashboardData).map((data, index) => (
                        <Grid item xs={6} sm={6} md={6} lg={3} key={index}>
                            <Box className='customCard1' >
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <AiOutlineBell size={25} />
                                    <Stack >
                                        <Typography sx={{ textTransform: "capitalize" }}>{data.replaceAll("_", " ")}</Typography>
                                        <Typography variant='h4' fontWeight={600}>{dashboardData[data]}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Card>
            {notificationListShow && <NotificationList />}
        </Stack >
    )
}
