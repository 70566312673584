import { Button, Grid, Stack, Typography } from '@mui/material'
import { default as React, useEffect, useContext, useCallback } from 'react'
import { AiOutlineDashboard } from "react-icons/ai"
import { GoPackageDependents } from "react-icons/go"
import { useNavigate } from 'react-router-dom'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import ReportSection from './ReportSection'
import NotificationSection from './notifications/NotificationSection'
import ChannelDashboardData from './ChannelDashboardData'
import UserSection from './cards/user'
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";

import { getParticularchannelAdminDashboardAPI, resetgetparticularchannelAdminDashboard } from "../../store/channel-admin/getDashboard";


export default function Home() {

  const navigate = useNavigate()
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state?.getparticularDetailschannelAdminDashboard);

  const handleOrderNow = () => {
    navigate('/courses')
  }

  const dashboardApiCall = useCallback(() => {
    dispatch(getParticularchannelAdminDashboardAPI());
    return () => {
      dispatch(resetgetparticularchannelAdminDashboard())
    }
  }, [dispatch])

  useEffect(() => {
    if (auth?.userData?.is_subadmin) {
      dashboardApiCall()
    }
  }, [auth, dashboardApiCall])
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Stack spacing={2}>
              <Grid container alignItems='center'>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <AiOutlineDashboard size={30} />
                    <Typography>Dashboard</Typography>
                  </Stack>
                </Grid>
              </Grid>
              {/* <DealorShip /> */}
              {auth?.userData?.is_subadmin ? (<>
                <ChannelDashboardData dashboardData={dashboardData?.result} />
              </>) : (<>
                <NotificationSection />
                <UserSection />
              </>)}
            </Stack>
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}>
            {auth?.userData?.is_subadmin ? (<>
            </>) : (<>
              <Stack spacing={2}>
                <Button startIcon={<GoPackageDependents />} size='medium' onClick={handleOrderNow} variant='outlined' sx={{ bgcolor: '#fff' }}>Go To Course</Button>
                <ReportSection />
              </Stack>
            </>)}
          </Grid>
        </Grid>
      </Body>
    </>
  )
}
