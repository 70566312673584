import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const EditsubadminApi = createAsyncThunk(
  "auth/Editsubadmin",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await AuthAxios.patch(apiRoutes.subadminAction("update", 1, data.id, 1), data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }

const EditsubadminSlice = createSlice({
  name: "Edit subadmin",
  initialState: intialState,
  reducers: {
    resetsubadmin: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(EditsubadminApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditsubadminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditsubadminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetsubadmin } = EditsubadminSlice.actions;
export const Editsubadminreducer = EditsubadminSlice.reducer;
