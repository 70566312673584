import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getParticularchannelAdminDashboardAPI = createAsyncThunk(
  "auth/channelAdminDashboard/getPaticular",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.channelAdminAction("dashboard",1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getparticularDetailschannelAdminDashboardSlice = createSlice({
  name: "get particular channelAdminDashboard",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetgetparticularchannelAdminDashboard: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getParticularchannelAdminDashboardAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticularchannelAdminDashboardAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getParticularchannelAdminDashboardAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetgetparticularchannelAdminDashboard } = getparticularDetailschannelAdminDashboardSlice.actions;
export const getparticularDetailschannelAdminDashboardReducer =
  getparticularDetailschannelAdminDashboardSlice.reducer;
