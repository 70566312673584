import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { hhwDeleteApi } from "../../store/hhw/deletehhw";
import { hhwApi } from "../../store/hhw/hhwList";
import { competitionDeleteApi } from "../../store/competition/deletecompetition";
import { competitionApi } from "../../store/competition/competitionList";
// import { channeltypeDeleteApi } from "../../store/channelType/DeleteChannelCode";
import { channeltypeApi } from "../../store/channelType/channelTypeList";
import { channelcodeDeleteApi } from "../../store/channelCode/deleteChannelCode";
import { channelcodeApi } from "../../store/channelCode/channelcodeList";

export default function DeleteChannelCode({ open, setOpen, id }) {
  const deleteSundayPoll = useSelector((state) => state?.hhwList);
  const { paginationModel } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(channelcodeDeleteApi(id));
    setOpen(false);
    if (!deleteSundayPoll?.loading) {
      dispatch(
        channelcodeApi({
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
        })
      );
      navigate("/channel-code");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this  ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              {/* <Button variant='outlined' onClick={notRequiredOrderHandler}>Not Required</Button> */}
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DeleteChannelCode.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
