import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";
export const channelBannerEditApi = createAsyncThunk(
  "auth/podcasts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(
        apiRoutes.channelBannerAction("update",1,data.id,1), data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const channelBannerEditSlice = createSlice({
  name: "channelBanner Edit",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(channelBannerEditApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channelBannerEditApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(channelBannerEditApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const channelBannerEditReducer = channelBannerEditSlice.reducer;
