import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SketchPicker } from "react-color";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { toast } from "react-hot-toast";
import { EditsurveyApi } from "../../store/survey/editSurvey";
import { addsurveyApi } from "../../store/survey/addSurvey";
import { getParticularsurveyAPI, resetgetparticularsurvey } from "../../store/survey/getSurveyById";
import { channelApi } from "../../store/channel/getchannelList";
import { DevTool } from "@hookform/devtools";
import ImageUploading from "react-images-uploading";
import { uploadImageApi } from "../../store/upload/uploadImage";
import BackdropUI from "../../ui/Backdrop";
import { AuthContext } from "../../context/AuthContext";

export default function EditSurvey() {
  const categoryDetails = useSelector((state) => state?.getparticularDetailssurvey);
  const {
    result: { title_name, video_link, title, heading, stars, category },
  } = categoryDetails;
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const channelList = useSelector((state) => state?.channelList);
  const [accountToggle, setAccountToggle] = useState("accountToggle");
  const [cardTitleDark, setCardTitleDark] = useState(null);
  const [colour, setColour] = useState();
  const uploadImage = useSelector((state) => state?.uploadImage);
  const [showColorPicker, setShowColorPicker] = useState();
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [value, setValue] = useState();
  const [imageLink, setImageLink] = useState();
  const ref = useRef(null);
  const params = useParams();
  const schema = yup
    .object({
      title: yup.string().required("Please provide a title ").trim(),
      link: yup.string().required("Please provide link ").trim(),
      language: yup.string().required("Please select language ").trim(),
      channel_code: yup.string().optional("Please select channel "),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      //console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };
  const categoryAdd = useSelector((state) => state?.addsurvey);
  const categoryEdit = useSelector((state) => state?.Editsurvey);
  useEffect(() => {
    //console.log(params.id);
    dispatch(channelApi());

    if (params.id) {
      dispatch(getParticularsurveyAPI(params.id));
    }
    return () => {
      dispatch(resetgetparticularsurvey())
    }
  }, []);
  const handleChangeComplete = (color) => {
    //console.log(color.hex);
    setColour(color.hex);
  };
  const handleUpdateForm = async (value) => {
    var newObj;
    if (colour) {
      newObj = { ...value, card_bg_color: colour, color: colour };
    } else {
      toast.error("Please Provide Color", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (imageLink) {
      newObj = { ...newObj, icon: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, icon: uploadImage.result };
    } else {
      toast.error("Please Upload An icon image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (params.id) {
      newObj = { ...value, ...newObj, id: params.id };
      await dispatch(EditsurveyApi(newObj));
      if (!categoryEdit?.loading) {
        navigate("/teacher-servey");
        //console.log("edit");
      }
    } else {
      await dispatch(addsurveyApi(newObj));
      if (!categoryAdd?.loading) {
        navigate("/teacher-servey");
        //console.log("true");
      }
    }
  };
  useEffect(() => {
    setCardTitleDark(categoryDetails.result?.is_card_title_dark);
    setColour(categoryDetails.result?.color);
    if (categoryDetails.result?.icon) {
      setImageLink(categoryDetails.result?.icon);
    }
  }, [categoryDetails]);
  useEffect(() => {
    let defalutValues = {};
    defalutValues["title"] = categoryDetails.result?.title;
    defalutValues["link"] = categoryDetails.result?.link;
    defalutValues["language"] = categoryDetails.result?.language;
    defalutValues["channel_code"] = categoryDetails.result?.channel_details?.assigned_code?.channel_code;
    reset(defalutValues);
  }, [categoryDetails]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Survey Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title
                      </InputLabel>
                      <OutlinedInput
                        {...register("title")}
                        label="title"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title?.message}
                        value={watch('title', "")}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-link" shrink>Redirection Link</InputLabel>
                      <OutlinedInput
                        {...register("link")}
                        label="Redirection Link"
                        notched
                        id="outlined-adornment-link"
                        error={errors?.link?.message}
                        value={watch('link', "")}
                      />
                      <FormHelperText error>
                        {errors?.link?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-language" shrink>
                        Language
                      </InputLabel>
                      <Select
                        {...register("language")}
                        label="Language"
                        notched
                        id="outlined-adornment-language"
                        error={!!errors?.language?.message}
                        value={watch('language', "")}
                      >
                        <MenuItem value={"hindi"}>Hindi</MenuItem>
                        <MenuItem value={"english"}>English</MenuItem>
                        <MenuItem value={"both"}>Both</MenuItem>
                      </Select>
                      <FormHelperText error>
                        {errors?.language?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Channel Code
                      </InputLabel>
                      <Select
                        {...register("channel_code")}
                        label="Channel Code"
                        id="outlined-adornment-category"
                        notched
                        // disabled={auth?.userData?.is_subadmin}
                        error={!!errors?.channel_code?.message}
                        value={watch('channel_code', "")}
                      >
                        {channelList?.result?.result?.map((data, index) => (
                          <MenuItem key={`${index}-${data?.assign_channel_code}`} value={data.assigned_code.channel_code}>{data?.channel_name}</MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.channel_code?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <InputLabel>Color</InputLabel>

                  <Stack direction="row" spacing={2}>
                    {showColorPicker ? (
                      <>
                        <SketchPicker
                          color={colour || ""}
                          onChange={(e) => handleChangeComplete(e)}
                        />
                      </>
                    ) : (<div style={{ width: "100%" }}>
                      Colour is {colour}
                      <Button
                        variant="contained"

                        style={{ backgroundColor: colour, marginLeft: "2%" }}
                        onClick={() => setShowColorPicker(true)}
                      >
                        {colour ? "Change" : "Select"}
                      </Button>

                    </div>
                    )}
                  </Stack>

                </Stack>


                {showColorPicker ? (
                  <Button
                    variant="contained"
                    onClick={() => setShowColorPicker(false)}

                  >
                    Set
                  </Button>
                ) : null}

                <Stack spacing={2} my={2}>
                  <InputLabel>
                    Icon Image
                  </InputLabel>
                  {imageLink ? (
                    <div className="image-item">
                      <img
                        src={imageLink}
                        alt=""
                        width={watch('channel_code', "") === "Splash" ? 200 : 320}
                        height={watch('channel_code', "") === "Splash" ? 200 : 180}
                      />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      acceptType={['png', 'jpeg', 'jpg']}
                      value={images}
                      onChange={onUploadImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div className="upload__image-wrapper">
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop Image here
                              </Button>
                            )}

                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width={watch('channel_code', "") === "Splash" ? 200 : 320}
                                  height={watch('channel_code', "") === "Splash" ? 200 : 180}
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/teacher-servey")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <DevTool control={control} />
      <BackdropUI open={uploadImage?.loading || categoryEdit?.loading || categoryAdd?.loading} />

    </>
  );
}
