import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const teachingCategoryDeleteApi = createAsyncThunk(
    'auth/teachingCategory/delete',
    async (data, { rejectWithValue }) => {
        try {
            const { id, teaching_category } = data
            const response = await AuthAxios.delete(apiRoutes.resourceCategoryAction(`${id}`, 1), { params: { teaching_category } })
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const teachingCategoryDeleteSlice = createSlice({
    name: 'teaching category delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(teachingCategoryDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingCategoryDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(teachingCategoryDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const teachingCategoryDeleteReducer = teachingCategoryDeleteSlice.reducer