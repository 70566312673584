import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const channelBannerDeleteApi = createAsyncThunk(
    'auth/channelBanners/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.channelBannerAction("delete",1,`${id}`, 1))
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const channelBannerDeleteSlice = createSlice({
    name: 'channelBanner delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(channelBannerDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(channelBannerDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(channelBannerDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const channelBannerDeleteReducer = channelBannerDeleteSlice.reducer