import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Card,
  Grid,
  FormControl,
} from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import BackdropUI from "../../ui/Backdrop";
import { assignedObjectListApi, resetAssignedObjectList } from "../../store/channel/assignedObjectsListing";
import { moduleListingApi, resetModuleListing } from "../../store/channel/moduleListing";
import { assignObjectToChannelApi } from "../../store/channel/assignObjectToChannel";
import { unAssignObjectFromChannelApi } from "../../store/channel/unAssignObjectFromChannel";
import NodataImg from "../../assets/images/noData.png";
import ChannelAssignedModuleList from "./channelAssignedModuleList"
import ChannelModuleList from "./channelModuleList"
export default function ChannelContent() {

  const { result: assignedObjectListResult, loading: assignedObjectLoading, total: assignedObjectTotal } = useSelector((state) => state?.assignedObjectList);
  const { result: moduleListingResult, loading: moduleListingLoading, total: moduleListingTotal } = useSelector((state) => state?.moduleListing);
  const { result: assignObjectToChannelResult, loading: assignObjectToChannelLoading } = useSelector((state) => state?.assignObjectToChannel);
  const schema = yup
    .object({})
    .required();
  const schemaEdit = yup
    .object({})
    .required();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(params.id ? schemaEdit : schema),
    defaultValues: {},
  });
  const [channelCategory, setChannelCategory] = useState("");
  const [queryOptionsChangeAssignedModule, setQueryOptionsChangeAssignedModule] = useState({});
  const [queryOptionsChangeModuleListing, setQueryOptionsChangeModuleListing] = useState({});
  const [paginationModelChangeAssignedModule, setPaginationModelChangeAssignedModule] = useState({
    page: 0,
    pageSize: 20,
  });
  const [paginationModelChangeModuleListing, setPaginationModelChangeModuleListing] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationModelAssignedMemoized = useMemo(() => paginationModelChangeAssignedModule, [paginationModelChangeAssignedModule]);
  const paginationModelModuleListingMemoized = useMemo(() => paginationModelChangeModuleListing, [paginationModelChangeModuleListing]);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);
  const createQueryParams = useCallback((paginationModel, queryOptions) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    return {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      channel_id: params.id,
      type: channelCategory
    };
  }, [params.id, channelCategory]);

  const callObjectList = useCallback(() => {
    const queryAssignedModule = createQueryParams(paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule);
    dispatch(assignedObjectListApi(queryAssignedModule));
  }, [createQueryParams, dispatch, paginationModelChangeAssignedModule, queryOptionsChangeAssignedModule])

  const callModulelistList = useCallback(() => {
    const queryModuleListing = createQueryParams(paginationModelChangeModuleListing, queryOptionsChangeModuleListing);
    dispatch(moduleListingApi(queryModuleListing));
  }, [paginationModelChangeModuleListing, queryOptionsChangeModuleListing, createQueryParams, dispatch])

  const handleChannelCategory = (category) => {
    setChannelCategory(category)
  }

  const NoRowsOverlay = () => (
    <Box className="noData">
      <img src={NodataImg} alt="no data found" />
    </Box>
  );

  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
  }, [params.id]);

  useEffect(() => {
    dispatch(resetAssignedObjectList())
    callObjectList()
  }, [paginationModelAssignedMemoized, queryOptionsChangeAssignedModule, channelCategory]);

  useEffect(() => {
    dispatch(resetModuleListing())
    callModulelistList()
  }, [paginationModelModuleListingMemoized, queryOptionsChangeModuleListing, channelCategory]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Channel Content</Typography>
        </Stack>
        <Stack>
          <Grid container gap={1}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("")
                  }}
                    color="primary"
                  >
                    All
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Podcast" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Podcast")
                  }}>
                    Podcast
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Courses" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Courses")
                  }}>
                    Courses
                  </Button>
                </FormControl>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "ShortVideos" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("ShortVideos")
                  }}>
                    ShortVideos
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Webinar" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Webinar")
                  }}>
                    Webinar
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Quiz" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Quiz")
                  }}>
                    Quiz
                  </Button>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card sx={{
                background: channelCategory === "Polls" && "rgba(12, 174, 235, 0.04)"
              }}>
                <FormControl variant="outlined" fullWidth>
                  <Button onClick={() => {
                    handleChannelCategory("Polls")
                  }}>
                    Polls
                  </Button>
                </FormControl>
              </Card>
            </Grid>
          </Grid>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <Typography variant="h3">Already added Channel Content</Typography>
        </Stack>
        <Paper elevation={0}>
          <ChannelAssignedModuleList getRowSpacing={getRowSpacing} NoRowsOverlay={NoRowsOverlay} channelCategory={channelCategory} channel_id={params.id} callModulelistList={callModulelistList}/>

        </Paper>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">

          <Typography variant="h3">Add New Channel Content</Typography>
        </Stack>
        <Paper elevation={0}>
          <ChannelModuleList getRowSpacing={getRowSpacing} NoRowsOverlay={NoRowsOverlay} channelCategory={channelCategory} channel_id={params.id} callObjectList={callObjectList} />
        </Paper>
      </Body>
      <BackdropUI open={moduleListingLoading || assignObjectToChannelLoading} />
    </>
  );
}