import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const subadminApi = createAsyncThunk(
  "auth/subadmins",
  async (data, { rejectWithValue }) => {
    try {
      const paramsData = new URLSearchParams(data);
      const response = await AuthAxios.get(
        apiRoutes.subadminList, { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  result: [],
  loading: false,
  error: null,
  message: "",
  total: 0,
}
const subadminSlice = createSlice({
  name: "subadmin",
  initialState,
  reducers: {
    resetsubadmin: () =>
      initialState
  },
  extraReducers: (builder) => {
    builder.addCase(subadminApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subadminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(subadminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetsubadmin } = subadminSlice.actions;

export const subadminReducer = subadminSlice.reducer;
