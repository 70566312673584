import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import * as yup from "yup";
import Logo from "../../assets/images/theTeacherAppLogo.svg";
import SnakBar from "../../common/SnakBar";
import { loginApi } from "../../store/auth/login";
import Style from "../../styles/login.module.scss";
import ForgetPassword from "./ForgetPassword";
import BrandInfo from "../../common/brand-info";
import { csrfToken } from "../../store/auth/csrfToken";
import { openEdxLogin } from "../../store/auth/openEdxToken";
import { AuthContext } from "../../context/AuthContext";
const schema = yup
  .object({
    username: yup.string().required("Username is Required."),
    password: yup
      .string()
      .required("Password is Required.")
      .min(
        8,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
      )
      .matches(/[a-z]/, "password must contain at least 1 lower case letter")
      .matches(/[0-9]/, "password must contain at least 1 number")
      .matches(/[$&/+,:;=?@#|'<>.^*()%!-]/, "password must contain at least 1 special character"),
  })
  .required();

export default function Login() {
  const loginSelector = useSelector((state) => state?.login);
  const { loading } = loginSelector;
  const auth = useContext(AuthContext);
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [forgetPassModal, setForgetPassModal] = useState(false);

  const handleLogin = async (data) => {
    var data1 = {
      "username": data.username,
      "password": data.password,
      "edx_username": data.username,
      "edx_password": data.password,
    }
    const loginData = await dispatch(loginApi(data1));
    if (loginData?.payload && !loginData?.payload?.error && loginData?.payload?.result) {
      // setOTPAuthModel(true);
      auth.userLogIn(true, loginData?.payload?.result?.access_token)
      // localStorage.setItem(
      //   "access_token",
      //   loginData?.payload?.result?.access_token
      // );
      // localStorage.setItem(
      //   "refresh_token",
      //   loginData?.payload?.result?.refresh_token
      // );
      localStorage.setItem("username", loginData?.payload?.result?.username);
      localStorage.setItem("edx_access", loginData?.payload?.result?.edx_access);
      localStorage.setItem("studio_access", loginData?.payload?.result?.studio_access);
      dispatch(csrfToken()).then((action) => {
        dispatch(openEdxLogin({
          email_or_username: data.username,
          next: "/",
          password: data.password,
          csrftoken: action.payload.csrfToken,
        })).then((action) => {
          navigate('/home');
        })
      })

    } else {
      setError("credError", {
        type: "custom",
        message: loginData?.payload?.message,
      });
    }
    setShowPassword(false);
  };

  if (auth.userLoggedIn) {
    return <Navigate to='/home' />
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <BrandInfo />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={Style?.login}>
          <form
            onSubmit={handleSubmit(handleLogin)}
            className={Style.innerLogin}
          >
            <Stack
              width={"50%"}
              spacing={2}
              justifyContent="center"
              marginBottom={1}
            >
              <Stack alignItems="center" py={3}>
                <img src={Logo} alt="logo" width={230} />
              </Stack>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-email">
                  User Name
                </InputLabel>
                <OutlinedInput
                  {...register("username")}
                  error={errors?.username?.message}
                  label="UserName"
                  id="outlined-adornment-password"
                  startAdornment={<EmailIcon style={{ marginRight: "2%" }} />}
                />
                <FormHelperText error>{errors?.username?.message}</FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  {...register("password")}
                  error={errors?.password?.message}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="outlined-adornment-password"
                  startAdornment={<LockIcon style={{ marginRight: "2%" }} />}
                  endAdornment={
                    showPassword ? (
                      <IconButton onClick={() => setShowPassword(false)}>
                        <Visibility />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setShowPassword(true)}>
                        <VisibilityOff />
                      </IconButton>
                    )
                  }
                />
                <FormHelperText error>
                  {errors?.password?.message}
                </FormHelperText>
              </FormControl>
              {/* <Typography
                align="right"
                onClick={handleForgetPassModal}
                variant="h6"
                fontWeight={600}
              >
                Forgot Password?
              </Typography> */}
              <Button
                disabled={loading}
                fullWidth
                variant="contained"
                type="submit"
                size="large"
              >
                {loading ? <CircularProgress /> : "Log In"}
              </Button>
              {errors?.credError?.message && (
                <SnakBar type="error" error={errors?.credError?.message} />
              )}
            </Stack>
          </form>
        </Grid>

      </Grid>

      <ForgetPassword open={forgetPassModal} setOpen={setForgetPassModal} />
      {/* <OTPLoginAuth open={OTPAuthModel} setOpen={setOTPAuthModel} /> */}
    </>
  );
}
