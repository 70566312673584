import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getParticularchannelcodeAPI = createAsyncThunk(
  "auth/channelcode/getPaticular",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.channelCodeAction("details",1,id,1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getparticularDetailschannelcodeSlice = createSlice({
  name: "get particular channelcode",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetgetparticularchannelcode: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getParticularchannelcodeAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticularchannelcodeAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getParticularchannelcodeAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetgetparticularchannelcode } = getparticularDetailschannelcodeSlice.actions;
export const getparticularDetailschannelcodeReducer =
  getparticularDetailschannelcodeSlice.reducer;
