import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const teachingCategoryApi = createAsyncThunk(
    'auth/teachingCategory',
    async (data, { rejectWithValue }) => {
        try {
            const paramsData = new URLSearchParams(data);
            const response = await AuthAxios.get(apiRoutes.resourceCategoryList, { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "", total: 0 }

const teachingCategorySlice = createSlice({
    name: 'teaching category list',
    initialState: intialState,
    reducers: {
        resetTeachingCategory: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(teachingCategoryApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingCategoryApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(teachingCategoryApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const { resetTeachingCategory } = teachingCategorySlice.actions;
export const teachingCategoryReducer = teachingCategorySlice.reducer