import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const unAssignObjectFromChannelApi = createAsyncThunk(
  "auth/unAssignObjectFromChannel",
  async (data, { rejectWithValue }) => {
    try {
      const { ID, type } = data
      const response = await AuthAxios.delete(
        apiRoutes.channelAction(`unassign-object/${type}/${ID}`,1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const unAssignObjectFromChannelSlice = createSlice({
  name: "unAssignObjectFromChannel",
  initialState: { result: [], loading: false, error: null, message: "", total: 0 },
  extraReducers: (builder) => {
    builder.addCase(unAssignObjectFromChannelApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unAssignObjectFromChannelApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results?.result??[];
      state.message = action?.payload?.message;
      state.total = action?.payload?.count
    });
    builder.addCase(unAssignObjectFromChannelApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const unAssignObjectFromChannelreducer = unAssignObjectFromChannelSlice.reducer;
