import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Paper, Stack, Typography, Chip } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbarWithoutDownload";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DeleteSurvey from './deleteChannelAdmin';
import { channelAdminApi } from '../../store/channel-admin/subChannelAdminList';
import DataGridComponent from "../../components/DataGridComponent";

export default function ManageChannelAdmin() {
  const navigate = useNavigate();

  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [isDownload, setIsDownload] = useState(false)

  const podcastSelector = useSelector((state) => state?.channelAdminList);
  const { result, loading } = podcastSelector;
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "username",
      headerName: "User Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 300,
      renderCell: (data) => (
        <>
          {data.row.admin_details.username}
        </>
      )
    },
    {
      field: "channel_code",
      headerName: "Channel Code",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 300,
      renderCell: (data) => (
        <>
          {data.row.channel_details.assigned_code.channel_code}
        </>
      )
    },
    {
      field: "channel_name",
      headerName: "Channel Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 300,
      renderCell: (data) => (
        <>
          {data.row.channel_details.channel_name}
        </>
      )
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          {params?.row?.is_active ? (
            <Chip color="success" label="Active" variant="outlined" />
          ) : (
            <Chip color="error" label="InActive" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 700,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => navigate(`/edit-channel-admin/${row.row.id}`)}
          >
            EDIT
          </Button>
        </>
      ),
    },


  ];

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items?.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
    };
    dispatch(channelAdminApi(query));
  }, [dispatch, paginationModel, queryOptions]);
  useEffect(() => {
    console.log("result", result)
  }, [result]);
  const addEntry = async () => {
    navigate("/add-channel-admin");
  };
  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Channel Admin</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addEntry}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            loading={podcastSelector.loading}
            rowCount={podcastSelector.total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{ height: 500 }}
            getRowId={(row) => `${row.id}`}
            getRowSpacing={getRowSpacing}
            rows={result?.result || []}
            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "channelCodeManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.channelCodeAction("list") })),
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress',
              },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteSurvey
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <BackdropUI open={isDownload} />

    </>
  );
}
