import { Card, Paper, Stack, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { webinarInterestedApi } from "../../store/webinar/interestedUsers";
import { useParams } from "react-router-dom";
import DataGridComponent from "../../components/DataGridComponent";
import apiRoutes from "../../store/apiConfigs";
import { downloadwebinarInterestedApi } from "../../store/webinar/downloadInterestedUser";

export default function ManageWebinar() {
  const params = useParams();
  const [isDownload, setIsDownload] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const dispatch = useDispatch();
  const columns = [
    { field: "user_name", headerName: "User Name", width: 150 },
    { field: "fullname", headerName: "Full Name", width: 150 },
    { field: "email", headerName: "email", width: 150 },
    { field: "gender", headerName: "gender", width: 150 },
    { field: "phone_number", headerName: "phone_number", width: 150 },
    { field: "school_name", headerName: "school_name", width: 150 },
    { field: "city", headerName: "city", width: 150 },

    { field: "state", headerName: "state", width: 150 },
    { field: "pincode", headerName: "pincode", width: 150 },
  ];
  const webinarInterestedUser = useSelector(
    (state) => state?.webinarInterestedUser
  );
  useEffect(() => {
    dispatch(
      webinarInterestedApi({
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        id: params?.id,
      })
    );
  }, [dispatch, paginationModel?.page, paginationModel?.pageSize, params?.id]);


  console.log("paginationModel", paginationModel);
  const addToCartHandler = async () => {
    console.log("paginationModel", paginationModel);
    dispatch(
      downloadwebinarInterestedApi({
        id: params?.id,
      }))

  };
  
  const handleApiCall = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}${apiRoutes.webinarsAction(`interested-users/${params?.id}?csv=true`, 0)}`
      const apiUrlWithParams = `${apiUrl}`;
      setIsDownload(true)
      const response = await fetch(apiUrlWithParams, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setIsDownload(false)
        throw new Error('Network response was not ok');
      }
      var fileName
      let contentDisposition = response.headers.get('Content-Disposition')
      if (contentDisposition) {
        // Use regex to capture the filename
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
          console.log("Filename:", fileName);
        } else {
          console.log("Filename not found in Content-Disposition header.");
        }
      } else {
        console.log("Content-Disposition header not present.");
      }
      const blob = await response.blob();

      // Create a URL for the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      if (fileName && fileName !== '') {
        link.setAttribute('download', `${fileName}`)
      } else {
        link.setAttribute('download', `batchreport_${new Date().getTime()}.csv`); // Specify the file name
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownload(false)
    } catch (error) {
      console.error('Error exporting data:', error);
      setIsDownload(false)
      alert('Failed to export data.');
    }
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Intrested User List</Typography>
        </Stack>

        <Card sx={{ py: 2 }}>
          {/* <Typography fontWeight={600} variant='h5' my={1}>Result</Typography> */}
          <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">

            <Typography fontWeight={600} variant="h5" my={1}>
              Total Interested Users : {webinarInterestedUser?.total}{" "}
            </Typography>

            <Stack direction="row" spacing={1}>
              <Button onClick={handleApiCall} variant="contained">
                Download
              </Button>
            </Stack>
          </Stack>
        </Card>

        <Paper elevation={0}>
          <DataGridComponent
            sx={{ height: 400, marginTop: 2 }}
            rows={
              webinarInterestedUser?.result?.map((user, index) => ({
                id: index,
                user_name: user.username || "",
                fullname: user.fullname || "N/A",
                email: user.email || "N/A",
                gender: user.gender || "N/A",
                phone_number: user.phone_number || "N/A",
                school_name: user.school_name || "N/A",
                city: user.city || "N/A",
                state: user.state || "N/A",
                pincode: user.pincode || "N/A",
              })) || []
            }
            columns={columns}
            loading={webinarInterestedUser.loading}
            rowCount={webinarInterestedUser.total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Paper>
      </Body>
    </>
  );
}
