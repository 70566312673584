import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const assignObjectToChannelApi = createAsyncThunk(
  "auth/assignObjectToChannel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        apiRoutes.channelAction('assign-object/list'), data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const assignObjectToChannelSlice = createSlice({
  name: "assignObjectToChannel",
  initialState: { result: [], loading: false, error: null, message: "", total: 0 },
  extraReducers: (builder) => {
    builder.addCase(assignObjectToChannelApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignObjectToChannelApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results?.result??[];
      state.message = action?.payload?.message;
      state.total = action?.payload?.count
    });
    builder.addCase(assignObjectToChannelApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const assignObjectToChannelreducer = assignObjectToChannelSlice.reducer;
