import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const addcompetitionApi = createAsyncThunk(
  "auth/addcompetition",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        apiRoutes.holidayHomeWorkList,
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const addcompetitionSlice = createSlice({
  name: "addcompetition",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(addcompetitionApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addcompetitionApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(addcompetitionApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const addcompetitionreducer = addcompetitionSlice.reducer;
