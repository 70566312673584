import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const addchannelAdminApi = createAsyncThunk(
  "auth/addchannelAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        apiRoutes.channelAdminAction("add", 0),
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const addchannelAdminSlice = createSlice({
  name: "addchannelAdmin",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(addchannelAdminApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addchannelAdminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(addchannelAdminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const addchannelAdminreducer = addchannelAdminSlice.reducer;
