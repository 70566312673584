import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const teachingCategoryDetailsApi = createAsyncThunk(
    'auth/teachingCategory/detail',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...rest } = data
            const response = await AuthAxios.get(apiRoutes.resourceCategoryAction(`${data?.id}`, 1), { params: rest })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const teachingCategoryDetailsSlice = createSlice({
    name: 'teaching category detail',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetTeachingCategoryDetails: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(teachingCategoryDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingCategoryDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(teachingCategoryDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetTeachingCategoryDetails } = teachingCategoryDetailsSlice.actions;
export const teachingCategoryDetailsReducer = teachingCategoryDetailsSlice.reducer