import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import apiRoutes from "../../store/apiConfigs";
import DataGridComponent from "../../components/DataGridComponent";
import { moduleListingApi, resetModuleListing } from "../../store/channel/moduleListing";
import { assignObjectToChannelApi } from "../../store/channel/assignObjectToChannel";
import CustomGridToolbar from "../../components/CustomGridToolbarWithoutDownload";
import { getGridStringOperators } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../context/AuthContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ChannelModuleList({ getRowSpacing, NoRowsOverlay, channelCategory, channel_id, callObjectList }) {
  const { result: moduleListingResult, loading: moduleListingLoading, total: moduleListingTotal } = useSelector((state) => state?.moduleListing);
  const [queryOptionsChangeAssignedModule, setQueryOptionsChangeAssignedModule] = useState({});
  const [queryOptionsChangeModuleListing, setQueryOptionsChangeModuleListing] = useState({});
  const [paginationModelChangeModuleListing, setPaginationModelChangeModuleListing] = useState({
    page: 0,
    pageSize: 20,
  });
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);

  const paginationModelModuleListingMemoized = useMemo(() => paginationModelChangeModuleListing, [paginationModelChangeModuleListing]);

  const onFilterChangeModuleListing = useCallback((filterModel) => {
    setQueryOptionsChangeModuleListing({ filterModel: { ...filterModel } });
  }, []);
  const createQueryParams = useCallback((paginationModel, queryOptions) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    return {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      channel_id,
      type: channelCategory
    };
  }, [channel_id, channelCategory]);

  const callModulelistList = useCallback(() => {
    const queryModuleListing = createQueryParams(paginationModelChangeModuleListing, queryOptionsChangeModuleListing);
    dispatch(moduleListingApi(queryModuleListing));
  }, [paginationModelChangeModuleListing, queryOptionsChangeModuleListing, createQueryParams, dispatch])

  const handleAddtoChannel = async (record) => {
    dispatch(assignObjectToChannelApi({
      type: record.module_type ? record.module_type === "Course" ? "Courses" : record.module_type : record.module_type,
      module_id: record.id,
      channel_id
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callObjectList()
        callModulelistList()
      }
    }).catch(err => console.log(err))

  }
  const handlePaginationModelChangeModuleListing = (model) => {
    if (
      paginationModelChangeModuleListing.page !== model.page ||
      paginationModelChangeModuleListing.pageSize !== model.pageSize
    ) {
      setPaginationModelChangeModuleListing(model);
    }
  };

  // Fetch assigned module data
  useEffect(() => {
    // dispatch(resetModuleListing())
    callModulelistList()
  }, [paginationModelModuleListingMemoized, queryOptionsChangeModuleListing, channelCategory, dispatch]);

  const moduleListingColumn = [
    {
      field: "title",
      headerName: "Title",
      width: 450,
      filterable: true,
      sortable: false,
      hideable: false
    },
    {
      field: "object_class",
      headerName: "Class",
      width: 450,
      filterable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      renderCell: (data) => (
        <>
          <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.object_class) && data?.row?.object_class.map((obj, index) => (`${obj['title']}, `))}</Typography>
        </>),
      hideable: false
    },
    {
      field: "object_subjects",
      headerName: "Subject",
      width: 450,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains',
      ),
      sortable: false,
      renderCell: (data) => (
        <>
          <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.object_subjects) && data?.row?.object_subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
        </>),
      hideable: false
    },
    {
      field: "module_type",
      headerName: "Content Type",
      width: 200,
      filterable: false,
      sortable: false,
      hideable: false
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (data) => (
        <>
          <Button
            color="info"
            endIcon={<AddIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => handleAddtoChannel(data.row)}
          >
            Add
          </Button>

        </>
      ),
    },
  ];
  return (<>
    <DataGridComponent
      loading={moduleListingLoading}
      rowCount={moduleListingTotal}
      pageSize={paginationModelChangeModuleListing?.pageSize}
      onPageSizeChange={(newPageSize) => {
        setPaginationModelChangeModuleListing({
          ...paginationModelChangeModuleListing,
          pageSize: newPageSize,
        })
      }}
      paginationModel={paginationModelModuleListingMemoized}
      onPaginationModelChange={handlePaginationModelChangeModuleListing}
      sx={{
        height: 500,
      }}
      getRowId={(row) => `${row.id}`}
      rows={moduleListingResult}
      getRowSpacing={getRowSpacing}
      columns={moduleListingColumn}
      onFilterModelChange={onFilterChangeModuleListing}
      slots={{
        noRowsOverlay: NoRowsOverlay,
        toolbar: (props) =>
        (CustomGridToolbar({
          ...props, moduleType: "moduleListing", queryOptions: queryOptionsChangeModuleListing, paginationModel: paginationModelChangeModuleListing, apiUrl: apiRoutes.channelAction('module/list'),
        })),
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'linear-progress',
        },
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  </>
  );
}
