import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SketchPicker } from "react-color";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { toast } from "react-hot-toast";
import { EditsubadminApi } from "../../store/sub-admin/editSubadmin";
import { addsubadminApi } from "../../store/sub-admin/addSubadmin";
import { getParticularsubadminAPI } from "../../store/sub-admin/getSubadminById";
import { DevTool } from "@hookform/devtools";

const schema = yup
  .object({
    username: yup
      .string()
      .required("Please provide a username")
      .trim()
      .matches(/^\S*$/, "Username cannot contain spaces"),
    password: yup
      .string()
      .optional("Password is Required.")
      .min(
        8,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
      )
      .matches(/[a-z]/, "password must contain at least 1 lower case letter")
      .matches(/[A-Z]/, "password must contain at least 1 upper case letter")
      .matches(/[0-9]/, "password must contain at least 1 number")
      .matches(/[$&/+,:;=?@#|'<>.^*()%!-]/, "password must contain at least 1 special character"),
    is_active: yup.string().required("Please provide a status ").trim(),
  })
  .required();

export default function EditSubadmin() {
  const categoryDetails = useSelector((state) => state?.getparticularDetailssubadmin);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const categoryAdd = useSelector((state) => state?.addsubadmin);
  const categoryEdit = useSelector((state) => state?.Editsubadmin);
  useEffect(() => {
    if (params.id) {
      dispatch(getParticularsubadminAPI(params.id));
    }
  }, [dispatch, params.id]);
  const handleUpdateForm = async (value) => {
    var newObj;
    newObj = { ...value };
    // return false;
    if (params.id) {
      newObj = { ...value, id: params.id };
      await dispatch(EditsubadminApi(newObj));
      if (!categoryEdit?.loading) {
        navigate("/subadmin-management");
        //console.log("edit");
      }
    } else {
      await dispatch(addsubadminApi(newObj));
      if (!categoryAdd?.loading) {
        navigate("/subadmin-management");
        //console.log("true");
      }
    }
  };
  useEffect(() => {
    let defalutValues = {};
    if (!categoryDetails.loading && params.id) {
      defalutValues["username"] = categoryDetails.result?.username;
      defalutValues["is_active"] = `${categoryDetails.result?.is_active}`;
    }
    reset(defalutValues);
  }, [categoryDetails, reset, params.id]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Subadmin Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-username" shrink>
                        Username
                      </InputLabel>
                      <OutlinedInput
                        {...register("username")}
                        label="Username"
                        notched
                        id="outlined-adornment-username"
                        error={errors?.username?.message}
                        defaultValue={watch('username', "")}
                      />
                      <FormHelperText error>
                        {errors?.username?.message}
                      </FormHelperText>
                    </FormControl>
                    {!params.id && (<>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password" shrink>
                          New Password
                        </InputLabel>
                        <OutlinedInput
                          {...register("password")}
                          label="New Password"
                          notched
                          id="outlined-adornment-password"
                          error={errors?.password?.message}
                          value={watch('password', "")}
                        />
                        <FormHelperText error>
                          {errors?.password?.message}
                        </FormHelperText>
                      </FormControl>
                    </>)}
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-is_active" shrink>
                        Status
                      </InputLabel>
                      <Select
                        {...register("is_active")}
                        label="Status"
                        notched
                        id="outlined-adornment-is_active"
                        error={!!errors?.is_active?.message}
                        defaultValue={watch('is_active', "")}
                      >
                        <MenuItem value={"true"}>Active</MenuItem>
                        <MenuItem value={"false"}>InActive</MenuItem>
                      </Select>

                      <FormHelperText error>
                        {errors?.is_active?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/subadmin-management")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <DevTool control={control} />

    </>
  );
}
