import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar, getGridStringOperators } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbarWithoutDownload";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DeleteChannel from './deleteChannel';
import { channelApi, resetChannelList } from '../../store/channel/getchannelList';
import { EditchannelApi, resetchannel } from "../../store/channel/editChannelList";
import CheckIcon from "@mui/icons-material/Check";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DataGridComponent from "../../components/DataGridComponent";
import { AuthContext } from "../../context/AuthContext";

export default function ManageChannel() {
  const navigate = useNavigate();

  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [isDownload, setIsDownload] = useState(false)
  const auth = useContext(AuthContext);
  const podcastSelector = useSelector((state) => state?.channelList);
  const hhwApprove = useSelector((state) => state?.hhwApprove);
  const { result, loading } = podcastSelector;
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetChannelList());
    }
  }, [])
  
  const callChannelList = useCallback(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      search: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(channelApi(query));
  }, [dispatch, paginationModel, queryOptions])

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const handlePublishUnpublishChannel = async (record) => {
    dispatch(EditchannelApi({
      published: !record.row.published,
      id: record.row.id
    })).then((action) => {
      if (!action.type.includes("rejected")) {
        callChannelList()
      }
    }).catch(err => console.log(err))
  }

  const columns = [
    {
      field: "channel_name",
      headerName: "Channel Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 250,
    },
    {
      field: "channel_code",
      headerName: "Channel Code",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (data) => (
        <>
          {data?.row?.assigned_code?.channel_code}
        </>)
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 500,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button color="success" variant="contained"
            endIcon={row?.row?.published ? <CheckIcon /> : <ArrowRightIcon />}
            onClick={() => handlePublishUnpublishChannel(row)}
          >
            {row?.row?.published ? "UnPublish" : "Publish"}
          </Button>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => navigate(`/edit-channel/${row.row.id}`)}
          >
            EDIT
          </Button>
          {!auth?.userData?.is_subadmin && (
            <Button
              color="error"
              sx={{ margin: "0px 10px" }}

              endIcon={<DeleteIcon />}
              variant="outlined"
              onClick={() => {
                setNotSelectedProductModel(true)
                setDeleteId(row.id)
              }}
            >
              Delete
            </Button>
          )}
          <Button
            color="info"
            sx={{ margin: "0px 10px" }}

            label="Available" variant="outlined"
            onClick={() => navigate(`/channel-content/${row.row.id}`)}
          >
            {"Content"}
          </Button>
        </>
      ),
    },


  ];

  useEffect(() => {
    callChannelList()
  }, [dispatch, paginationModel, queryOptions, callChannelList]);

  const addEntry = async () => {
    navigate("/add-channel");
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Channel List</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addEntry}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            loading={podcastSelector.loading}
            rowCount={podcastSelector.total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result?.result || []}
            getRowSpacing={getRowSpacing}

            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "channelManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.channelAction("list") })),
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress',
              },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteChannel
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <BackdropUI open={isDownload} />

    </>
  );
}
