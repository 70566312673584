import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const entryExitDetailsApi = createAsyncThunk(
    'auth/entryExits/detail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.entryExitAction(`${id}`, 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "", total: 0 }
const entryExitDetailsSlice = createSlice({
    name: 'entryExit detail',
    initialState: intialState,
    reducers: {
        resetentryExit: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(entryExitDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(entryExitDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(entryExitDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetentryExit } = entryExitDetailsSlice.actions;
export const entryExitDetailsReducer = entryExitDetailsSlice.reducer