import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const channelApi = createAsyncThunk(
  "auth/channels",
  async (data, { rejectWithValue }) => {
    try {
      const paramsData = new URLSearchParams(data);
      const response = await AuthAxios.get(
        apiRoutes.channelAction("list"), { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = { result: [], loading: false, error: null, message: "", total: 0 }
const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    resetChannelList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(channelApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channelApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(channelApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetChannelList } = channelSlice.actions;
export const channelReducer = channelSlice.reducer;
