import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const EditchannelcodeApi = createAsyncThunk(
  "auth/Editchannelcode",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await AuthAxios.patch(apiRoutes.channelCodeAction("update",1,data.id,1),data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }

const EditchannelcodeSlice = createSlice({
  name: "Edit channelcode",
  initialState:intialState,
  reducers: {
    resetchannelcode: () => intialState,
},
  extraReducers: (builder) => {
    builder.addCase(EditchannelcodeApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditchannelcodeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditchannelcodeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetchannelcode } = EditchannelcodeSlice.actions;
export const Editchannelcodereducer = EditchannelcodeSlice.reducer;
