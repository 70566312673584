import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { settingAddApi } from "../../store/settings/settingAdd";
import { settingEditApi } from "../../store/settings/settingEdit";
import { getCertificateParticularApi } from "../../store/settings/getParticularCertificate";
import { resetCertificateUpload, uploadCertificateApi } from "../../store/upload/uploadCertificates";
import { uploadCertificatehtmltextApi } from "../../store/settings/uploadCertificateHtmlText";
const schema = yup
  .object({

    title: yup.string().required("Please Provide A category ").trim(),
  })
  .required();

export default function UploadCertificate() {
  const particularcertificate = useSelector((state) => state?.particularcertificate);
  const { result } = particularcertificate
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const classAdd = useSelector((state) => state?.classAdd);
  const classEdit = useSelector((state) => state?.classEdit);
  useEffect(() => {
    if (params.id) {
      dispatch(getCertificateParticularApi(params.id));
    }
  }, [dispatch]);
  const handleUpdateForm = async (value) => {
    return
    var newObj;

    if (params.id) {
      newObj = { ...value, id: params.id };
      await dispatch(settingEditApi(newObj));
      if (!classEdit?.loading) {
        navigate("/settings");
      }
    } else {
      await dispatch(settingAddApi(value));
      if (!classAdd?.loading) {
        navigate("/settings");
      }
    }
  };

  // useEffect(() => {
  //   let defalutValues = {};
  //   if (params.id) {
  //     defalutValues["title"] = settingDetails?.result?.title;
  //     defalutValues["min_percent"] = settingDetails?.result?.min_percent;
  //     defalutValues["max_percent"] = settingDetails?.result?.max_percent;
  //   }
  //   reset(defalutValues);
  // }, [settingDetails]);
  const uploadCertificate = useSelector((state) => state?.uploadCertificate);



  const refInput = useRef(null);
  const capitalizeFileName = (fileName) => {
    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  const onUploadHandout = async (file) => {
    let formData = new FormData();
    const capitalizedFileName = capitalizeFileName(file.name);
    const capitalizedFile = new File([file], capitalizedFileName, { type: file.type });
    formData.append("folder", capitalizedFile);
    const val = await dispatch(uploadCertificateApi(formData));
    await dispatch(uploadCertificatehtmltextApi({ html_text: val?.payload?.result, certificate_type: params.id }))

    await dispatch(resetCertificateUpload())
    if (params.id) {
      dispatch(getCertificateParticularApi(params.id));
    }
    refInput.current.value = null;
  };

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Certificate</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <input
                    accept="application/zip"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    ref={refInput}
                    onChange={(e) => onUploadHandout(e.target?.files[0])}
                  />

                  {result?.html_text && <div className="iframe-container" style={{ width: '100%', height: '400px', border: 'none' }}>
                    <iframe
                      src={result?.html_text}
                      style={{
                        transform: 'scale(0.75)',
                        transformOrigin: 'top left',
                        width: '140.33%',
                        height: '140.33%'
                      }}
                      frameBorder="0"
                      allowFullScreen
                      title="Iframe Preview"
                    ></iframe>
                  </div>}
                  <Button
                    type="button"
                    // sx={{ float: "right", mt: 2, ml: 2 }}
                    variant="contained"
                    onClick={() => refInput.current.click()}

                  >
                    Upload Zip
                  </Button>
                </Stack>

              </Card>

            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
