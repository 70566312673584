import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const channelBannerAddApi = createAsyncThunk(
  "auth/channelBanners/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.channelBannerAction("add"), data)
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const channelBannerAddSlice = createSlice({
  name: "channelBanner Add",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(channelBannerAddApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channelBannerAddApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(channelBannerAddApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const channelBannerAddReducer = channelBannerAddSlice.reducer;
