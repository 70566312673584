import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const EditsurveyApi = createAsyncThunk(
  "auth/Editsurvey",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await AuthAxios.patch(apiRoutes.surveyAction(data.id,1),data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }

const EditsurveySlice = createSlice({
  name: "Edit survey",
  initialState:intialState,
  reducers: {
    resetsurvey: () => intialState,
},
  extraReducers: (builder) => {
    builder.addCase(EditsurveyApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditsurveyApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditsurveyApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetsurvey } = EditsurveySlice.actions;
export const Editsurveyreducer = EditsurveySlice.reducer;
