import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { ThemeContext } from '../../context/ThemeContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteLessonPlan from "./delete-lesson-plan";
import { teachingCategoryApi } from "../../store/teachingCategory/teachingCategoryList";
import { resetTeachingCategory } from "../../store/teachingCategory/teachingCategoryList";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DataGridComponent from "../../components/DataGridComponent";
import PublishUnpublishModule from "../../components/PublishUnpublishModule";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function ManageLessonPlan() {
  const teachingCategoryList = useSelector((state) => state?.teachingCategory);
  const { result, loading, total } = teachingCategoryList;
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState(false)
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [queryOptions, setQueryOptions] = useState({});
  const [publishModel, setPublishModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },
    {
      field: "topics",
      headerName: "Topics",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 200,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.topics) && data?.row?.topics.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "subjects",
      headerName: "Subjects",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "thumbnail",
      headerName: " Image",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Box sx={{ py: 1 }}><img width={200} height={100} src={params.row.thumbnail} alt='story image' /></Box>)
    },
    {
      field: "classes",
      headerName: "Classes",
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.classes) && data?.row?.classes.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "update_podcast",
      headerName: "Update",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(`/lesson-plan-details/${row.id}`)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_schoolresource",
      headerName: "Delete",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
    {
      field: "publish",
      headerName: "Publish Podcast",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            label="Available" variant="outlined"
            onClick={() => {
              setPublishModel(true)
              setSelectedRow(row.row)
            }}
            endIcon={<ArrowRightAltIcon />}
          >
            {row.row.published ? "Unpublish" : "Publish"}

          </Button>
        </>
      ),
    },
  ];

  const addToCartHandler = async () => {
    navigate("/add-lesson-plan-details");
  };

  const getLessonPlanList = useCallback((queryOptions, paginationModel) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      teaching_category: "lesson_plan"
    };
    dispatch(teachingCategoryApi(query));
  }, [dispatch])

  useEffect(() => {
    getLessonPlanList(queryOptions, paginationModel)
    dispatch(resetTeachingCategory());
  }, [dispatch, queryOptions, paginationModel, getLessonPlanList]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Lesson Plan</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{
              height: 500
            }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "lesson_plan", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.entryExitList })),
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress',
              },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteLessonPlan
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <PublishUnpublishModule
        open={publishModel}
        setOpen={setPublishModel}
        selectedRow={selectedRow}
        queryOptions={queryOptions}
        paginationModel={paginationModel}
        moduleType={"lesson_plan"}
        moduleList={getLessonPlanList}
      />
      <BackdropUI open={isDownload} />
    </>
  );
}
