import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { EditchannelAdminApi } from "../../store/channel-admin/editChannelAdmin";
import { addchannelAdminApi } from "../../store/channel-admin/addChannelAdmin";
import { getParticularchannelAdminAPI } from "../../store/channel-admin/getChannelAdminById";
import { subadminApi } from '../../store/sub-admin/subadminList';
import { channelApi } from "../../store/channel/getchannelList";
import { DevTool } from "@hookform/devtools";

const schema = yup
  .object({
    channel_id: yup.string().required("Please choose channel code ").trim(),
    user_id: yup.string().optional("Please subadmin ").trim(),
    is_active: yup.string().required("Please provide a status ").trim(),
  })
  .required();

export default function EditChannelAdmin() {
  const categoryDetails = useSelector((state) => state?.getparticularDetailschannelAdmin);
  const channelList = useSelector((state) => state?.channelList);
  const subadminList = useSelector((state) => state?.subadminList);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const categoryAdd = useSelector((state) => state?.addchannelAdmin);
  const categoryEdit = useSelector((state) => state?.EditchannelAdmin);
  useEffect(() => {
    dispatch(subadminApi());
    dispatch(channelApi());
    if (params.id) {
      dispatch(getParticularchannelAdminAPI(params.id));
    }
  }, [dispatch, params.id]);
  const handleUpdateForm = async (value) => {
    var newObj;
    value = { ...value, status: value?.is_active };
    // return false;
    if (params.id) {
      newObj = { ...value, id: params.id };
      await dispatch(EditchannelAdminApi(newObj));
      if (!categoryEdit?.loading) {
        navigate("/channeladmin-management");
        //console.log("edit");
      }
    } else {
      await dispatch(addchannelAdminApi(value));
      if (!categoryAdd?.loading) {
        navigate("/channeladmin-management");
        //console.log("true");
      }
    }
  };
  useEffect(() => {
    let defalutValues = {};
    if (!categoryDetails.loading && params.id) {
      defalutValues["channel_id"] = categoryDetails.result?.channel;
      defalutValues["user_id"] = categoryDetails.result?.admin;
      defalutValues["is_active"] = `${categoryDetails.result?.is_active}`;
      reset(defalutValues);
    }
  }, [categoryDetails, reset, params.id]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Channel Admin Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-channel" shrink>
                        Channel Code
                      </InputLabel>
                      <Select
                        {...register("channel_id")}
                        label="Channel Code"
                        id="outlined-adornment-channel"
                        notched
                        disabled={params.id ? true : false}
                        error={!!errors?.channel_id?.message}
                        value={watch('channel_id', "")}
                      >
                        {channelList?.result?.result?.map((data, index) => (
                          <MenuItem key={`${index}-${data?.assign_channel_code}`} value={data.id}>{data?.channel_name}</MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.channel_id?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-user_id" shrink>
                        Subadmin
                      </InputLabel>
                      <Select
                        {...register("user_id")}
                        label="Subadmin"
                        id="outlined-adornment-user_id"
                        notched
                        disabled={params.id ? true : false}
                        error={!!errors?.user_id?.message}
                        value={watch('user_id', "")}
                      >
                        {subadminList?.result?.result?.map((data, index) => (
                          <MenuItem key={`${index}-${data?.id}`} value={data.id}>{data?.username}</MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.user_id?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-status" shrink>
                        Status
                      </InputLabel>
                      <Select
                        {...register("is_active")}
                        label="Status"
                        notched
                        id="outlined-adornment-status"
                        error={!!errors?.is_active?.message}
                        value={watch('is_active', "")}
                      >
                        <MenuItem value={"true"}>Active</MenuItem>
                        <MenuItem value={"false"}>InActive</MenuItem>
                      </Select>

                      <FormHelperText error>
                        {errors?.is_active?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/channeladmin-management")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <DevTool control={control} />

    </>
  );
}
