import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const channelAdminApi = createAsyncThunk(
  "auth/channelAdmins",
  async (data, { rejectWithValue }) => {
    try {
      const paramsData = new URLSearchParams(data);
      const response = await AuthAxios.get(
        apiRoutes.channelAdminList, { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  result: [],
  loading: false,
  error: null,
  message: "",
  total: 0,
}
const channelAdminSlice = createSlice({
  name: "channelAdmin",
  initialState,
  reducers: {
    resetchannelAdmin: () =>
      initialState
  },
  extraReducers: (builder) => {
    builder.addCase(channelAdminApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(channelAdminApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(channelAdminApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetchannelAdmin } = channelAdminSlice.actions;

export const channelAdminReducer = channelAdminSlice.reducer;
